import React, { useState, useEffect, useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
});

const navItems = ["Home", "Menu", "About", "Contact"];

export default function DrawerAppBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const prevScrollY = useRef(0);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      // Determine scrolling direction (up or down)
      const isScrollingUp = scrollTop < prevScrollY.current;
      prevScrollY.current = scrollTop;

      // Adjust the threshold as needed (e.g., 50)
      const showThreshold = 50;

      // Show the navigation bar instantly when scrolling up
      setIsNavbarVisible(isScrollingUp || scrollTop < showThreshold);
      setShowBackToTop(scrollTop > window.innerHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawerBackground = "#000000";

  const drawerTextStyle = {
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <AppBar
          style={{
            display: isNavbarVisible ? "block" : "none",
            border: "2px solid black", // White border
            height: "90px",
          }}
        >
          <Toolbar>
            <Link to="/">
              <img
                src={require("../assets/images/logo.png")}
                alt="Logo"
                style={{
                  width: "160px",
                  height: "80px",
                  cursor: "pointer",
                }}
              />
            </Link>
            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <MenuIcon onClick={toggleDrawer} sx={{ color: "white" }} />
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={toggleDrawer}
                sx={{ "& .MuiDrawer-paper": { background: drawerBackground } }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "20px",
                  }}
                  role="presentation"
                  onClick={toggleDrawer}
                  onKeyDown={toggleDrawer}
                >
                  <Link to="/">
                    <img
                      src={require("../assets/images/logo.png")}
                      alt="Logo"
                      style={{
                        marginTop: "50px",
                        marginBottom: "50px",
                        width: "250px",
                        height: "100px",
                      }}
                    />
                  </Link>
                  <CloseIcon
                    onClick={toggleDrawer}
                    sx={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      cursor: "pointer",
                      color: "white",
                    }}
                  />
                  <List>
                    {navItems.map((item) => (
                      <Link
                        style={{ textDecoration: "none" }}
                        key={item}
                        to={`/${item.toLowerCase()}`}
                      >
                        <ListItem button key={item}>
                          <ListItemText
                            primary={item}
                            primaryTypographyProps={{
                              style: drawerTextStyle,
                            }}
                          />
                        </ListItem>
                      </Link>
                    ))}
                    {/* Add the Order Now link to the drawer */}
                    {/* <a
                      href="https://order.online/store/shawarma-station-coldwater-27653865/?hideModal=true&pickup=true"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      <ListItem button>
                        <ListItemText
                          primary="Order Now"
                          primaryTypographyProps={{
                            style: drawerTextStyle,
                          }}
                        />
                      </ListItem>
                    </a> */}
                  </List>
                </Box>
              </Drawer>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <Box>
                {navItems.map((item) => (
                  <Link
                    style={{ textDecoration: "none" }}
                    key={item}
                    to={`/${item.toLowerCase()}`}
                  >
                    <Button key={item} style={{ color: "white" }}>
                      {item}
                    </Button>
                  </Link>
                ))}
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box>
          <Toolbar />
        </Box>
        {/* Back-to-Top Arrow */}
        {showBackToTop && (
          <Box
            sx={{
              position: "fixed",
              bottom: "5px",
              left: "10%", // Center the arrow horizontally
              transform: "translateX(-50%)", // Center the arrow horizontally
              cursor: "pointer",
              zIndex: 1000,
            }}
          >
            <KeyboardArrowUpIcon
              onClick={scrollToTop}
              fontSize="large" // Adjust the size as needed
              style={{ color: "orange" }}
              sx={{ fontSize: "6rem" }} // Add this line to increase the size
            />
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
