import React, { useEffect } from "react";
import { Box } from "@mui/material";
import styled, { keyframes } from "styled-components";
import OurServices from "./OurServices"; // Import the image of the restaurant
// Import the image of the restaurant
const restaurantImage = require("../assets/images/restaurant.webp");

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  animation: ${fadeIn} 1s ease forwards;
`;

const StyledLine = styled.div`
  position: relative;
  width: 30%;
  height: 2px;
  background-color: #000000; /* Add your desired color here */
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #000000;
  margin: 1rem 0;
  animation: ${slideIn} 1s ease forwards;
`;

const RestaurantImage = styled.img`
  width: 100%; /* Adjust the width as needed */
  max-width: 600px; /* Set a maximum width if needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 16px; /* Adjust as needed */

  @media (max-width: 768px) {
    max-width: 300px; /* Set a smaller width for mobile devices */
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #666;
  max-width: 800px;
  margin: 0 auto 6rem;
  padding: 0 20px;
  text-align: justify;
  animation: ${fadeIn} 1s ease forwards;
`;

function About() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  return (
    <div style={{ marginTop: "70px" }}>
      <TitleWrapper>
        <StyledLine />
        <Title>About Us</Title>
        <StyledLine />
      </TitleWrapper>

      {/* Add the restaurant image */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "2rem", // Adjust the margin as needed
        }}
      >
        <RestaurantImage src={restaurantImage} alt="Restaurant" />
      </Box>

      {/* Add the paragraph */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          borderRadius: "16px",
          marginBottom: "-5rem",
        }}
      >
        <Paragraph>
          Experience the vibrant flavors of Iraqi street food right here at our
          restaurant. Our culinary journey takes you through the bustling
          streets of Iraq, where each dish tells a story of tradition, culture,
          and passion. From sizzling kebabs to fragrant rice dishes, every bite
          is a celebration of authentic Iraqi cuisine. Join us as we bring the
          essence of Iraqi street food to your table.
        </Paragraph>
      </Box>

      <Box
        sx={{
          borderRadius: "16px",
          marginBottom: "3rem",
        }}
      >
        <OurServices />
      </Box>
    </div>
  );
}

export default About;
