import React, { useEffect } from 'react';

// Update the component to handle background color and styling for reviews
const Reviews = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Dynamically load the Elfsight script
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.setAttribute('data-use-service-core', 'true');
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: '70px',
        padding: '20px',
        minHeight: '50vh',
        textAlign: 'center',
      }}
    >
      <div
        className="elfsight-app-4a21330f-c16d-4ad1-95b2-c943292dd4ef"
        data-elfsight-app-lazy
        style={{
          borderRadius: '10px',
          padding: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          maxWidth: '100%',
          margin: '0 auto',
        }}
      ></div>
    </div>
  );
};

export default Reviews;
