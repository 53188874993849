import React from "react";
import styled from "styled-components";
// import OurServices from "./OurServices";
import WhatWeOffer from "./WhatWeOffer";
import Gallery from "./Gallery";
import Reviews from "./Reviews";
import Video from "./Video";
const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px; /* Adjust the margin-top value as needed */
  height: auto; /* Adjust height for better mobile responsiveness */
  position: relative; /* Add relative positioning */
`;

const BackgroundContainer = styled.div`
  background-image: url(${require("../assets/images/main.webp")});
  transition: transform 0.3s ease-in-out;
  background-repeat: no-repeat;

  @media screen and (min-width: 768px) {
    background-position: center;
    position: relative;
    background-size: cover; /* Update to cover the entire viewport */
    height: 50vh; /* Adjust the height as needed */
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10px; 

    background-size: cover; /* Update to cover the entire viewport */
    height: 20vh; /* Adjust the height as needed */
    width: 100%;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

const OrderButton = styled.a`
  font-size: 1.2rem;
  padding: 1rem 2rem;
  border-radius: 5px;
  background: #000000;
  color: #ffffff;
  border: 2px solid #ffffff; /* Add white border */
  cursor: pointer;
  transition: all 0.3s ease;
  position: fixed;
  bottom: 20px;
  right: 20px;
  text-decoration: none;
  z-index: 999; /* Add a higher z-index value */

  @media screen and (max-width: 768px) {
    max-width: 120px; /* Set max-width to ensure the button doesn't become too wide */
    text-align: center;
    right: 20px;
    bottom: 20px;
  }

  &:hover {
    background: linear-gradient(to right, #000000, #daa520);
    border-color: #daa520; /* Change border color on hover */
  }
`;

const HomePage: React.FC = () => {
  return (
    <>
      <HomePageContainer>
        <BackgroundContainer />
        <OrderButton
          href="https://www.doordash.com/en-CA/store/iq-dijla-mississauga-28023558/?srsltid=AfmBOoqLIm3mLofwlzxh5XXZHTqDtsiU_N9Y6Fy4buC5JhV0okoIJRlq"
          target="_blank" // Open link in a new tab
        >
          Order Now
        </OrderButton>
      </HomePageContainer>
      <WhatWeOffer />
      <Reviews/>
      <Gallery />
      <Video/>
    </>
  );
};

export default HomePage;
