import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Styled Components for the Masonry Layout
const GalleryWrapper = styled.div`
  margin: 2rem auto;
  padding: 1rem;
  max-width: 1200px;
  text-align: center; /* Center the title */
`;

const Title = styled.h2`
  font-size: 40px;
  margin-bottom: 1.5rem;
`;

const MasonryGrid = styled.div`
  column-count: 3;
  column-gap: 1rem;

  @media (max-width: 768px) {
    column-count: 1; /* On smaller screens, stack all images in one column */
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    column-count: 2;
  }
`;

const MasonryItem = styled.div`
  margin-bottom: 1rem;
  break-inside: avoid;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05); /* Slight scale-up on hover */
  }
`;

const ImageContainer = styled.div`
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
`;

const CarouselImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

// Main Gallery Component
const Gallery: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    const importImages = async () => {
      const importedImages: string[] = [];

      // Webpack dynamic image import
      const imageContext = (require as any).context(
        "../assets/images/gallery",
        false,
        /\.(webp)$/
      );

      imageContext.keys().forEach((imageName: string) => {
        const image = imageContext(imageName) as string;
        importedImages.push(image);
      });

      setImages(importedImages);
    };

    importImages();
  }, []);

  return (
    <GalleryWrapper>
      {/* Title for the gallery */}
      <Title>Gallery</Title>

      <MasonryGrid>
        {images.map((image, index) => (
          <MasonryItem key={index}>
            <ImageContainer>
              <CarouselImage src={image} alt={`Gallery image ${index + 1}`} />
            </ImageContainer>
          </MasonryItem>
        ))}
      </MasonryGrid>
    </GalleryWrapper>
  );
};

export default Gallery;
