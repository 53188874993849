import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const Section = styled.section`
  padding: 50px 0;
  animation: ${fadeIn} 1s ease forwards;
`;

const TitleWrapper = styled.div`
  position: relative;
  margin-bottom: 3rem;
`;

const StyledLine = styled.div`
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  left: 50%;
  width: 30%;
  transform: translateX(-50%);
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #000000;
  text-align: center;
  z-index: 1;
  position: relative;
  margin-bottom: 30px;
  padding-top: 2rem;
  animation: ${slideIn} 1s ease forwards;
`;

const ContactInfo = styled.div`
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Card = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  cursor: pointer;
  &:hover {
    transform: translateY(-5px);
  }
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: #000000;
  margin-bottom: 10px;
`;

const CardContent = styled.p`
  font-size: 1rem;
  color: #666;
`;

const InstagramLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #000000;
`;

const InstagramIcon = styled.img`
  width: 70px;
  height: auto;
`;

const MapContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const MapFrame = styled.iframe`
  width: 100%;
  height: 400px;
  border: 0;
  border-radius: 10px;
`;

const Address = styled.p`
  margin-top: 30px;
  text-align: center;
  font-size: 1.2rem;
  color: #666;
`;

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const handlePhoneClick = () => {
    window.location.href = "tel:+12895210403";
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <Container>
        <Section>
          <TitleWrapper>
            <StyledLine />
            <Title>Contact Us</Title>
            <StyledLine />
          </TitleWrapper>
          <ContactInfo>
            <Card>
              <CardTitle>Phone</CardTitle>
              <CardContent
                onClick={handlePhoneClick}
                style={{ cursor: "pointer" }}
              >
                (289) 521-0403
              </CardContent>
            </Card>
            <Card>
              <CardTitle>Email</CardTitle>
              <CardContent>
                <a href="mailto:ahm51incorporated@gmail.com">
                  ahm51incorporated@gmail.com
                </a>
              </CardContent>
            </Card>
            <Card>
              <CardTitle>Hours</CardTitle>
              <CardContent>Sun-Sat: 11pm - 9pm</CardContent>
            </Card>
            <Card>
              <CardContent>
                <InstagramLink href="https://www.instagram.com/iq_dijla_restaurant?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                  <InstagramIcon
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png"
                    alt="Instagram"
                  />
                </InstagramLink>
              </CardContent>
            </Card>
          </ContactInfo>
        </Section>
        <Section>
          <MapContainer>
            <MapFrame
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28719.309071654564!2d-79.66620458932626!3d43.57249745319159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b4b80da2f7ac5%3A0x4a17bca157a2a2f9!2s255%20Dundas%20St%20W%2C%20Mississauga%2C%20ON%20L5B%201V5%2C%20Canada!5e0!3m2!1sen!2sus!4v1649360751140!5m2!1sen!2sus"
              allowFullScreen
              loading="lazy"
            ></MapFrame>
          </MapContainer>
          <Address>
            255 Dundas St W #7a, Mississauga, ON L5B 1V5, Canada
          </Address>
        </Section>
      </Container>
    </div>
  );
};

export default ContactUs;
