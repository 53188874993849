import React from "react";
import styled from "styled-components";

// Styled component for VideoContainer
const VideoContainer = styled.div`
  width: 100vw; /* Full viewport width */
  display: flex;
  justify-content: center;
  margin-top: 20px;
  overflow: hidden; /* Ensure no overflow from the video */
`;

// Styled component for the video (EmbedVideo)
const EmbedVideo = styled.video`
  width: 100vw; /* Full viewport width */
  height: 80vh; /* Adjust height as needed */
  object-fit: cover; /* Cover the container while maintaining aspect ratio */
  transform: scale(0.9); /* Zoom out effect */
  transform-origin: center center; /* Center the video */
  border-radius: 0; /* Remove border-radius if not needed */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    width: 100vw; /* Full width on smaller screens */
    height: 50vh; /* Adjust height for smaller screens */
    transform: scale(0.8); /* Zoom out effect for smaller screens */
  }
`;

// Video component to include in your homepage or elsewhere
const Video: React.FC = () => {
  return (
    <VideoContainer>
      <EmbedVideo
        src={require("../assets/videos/dijla.mp4")} // path to your video file
        className="homepage-video"
        autoPlay
        loop
        muted
        controls={false} // Hide the default video controls
      />
    </VideoContainer>
  );
};

export default Video;
